// import E from '../../modules/come-thru/E';
// import _ from 'lodash';
// import isString from 'lodash/isString';

function create(properties) {
    let {tag, id, classList, style, attributes, dataset, innerText, innerHTML, children, onclick} = properties;

    const element = document.createElement(tag);

    if (id) element.id = id;

    if (dataset) Object.entries(dataset).forEach(([k, v]) => element.dataset[k] = v);

    if (style) Object.entries(style).forEach(([k, v]) => element.style[k] = v);

    if (attributes) Object.entries(attributes).forEach(([k, v]) => element.setAttribute(k, v));

    if (classList) element.classList.add(...classList);

    if (innerText) element.innerText = innerText;

    if (innerHTML) element.innerHTML = innerHTML;

    if (children) element.append(...children);

    if (onclick) element.onclick = onclick;

    return element;
}

function c(tag, properties) {
    let {id, classList, style, attributes, innerText, innerHTML, children, onclick} = properties;

    const element = document.createElement(tag);

    if (id) element.id = id;

    if (style) Object.entries(style).forEach(([k, v]) => element.style[k] = v);

    if (attributes) Object.entries(attributes).forEach(([k, v]) => element.setAttribute(k, v));

    if (classList) element.classList.add(...classList);

    if (innerText) element.innerText = innerText;

    if (innerHTML) element.innerHTML = innerHTML;

    if (children) element.append(...children);

    if (onclick) element.onclick = onclick;

    return element;
}

function div3(props) {
    const el = document.createElement('div');
    Object.assign(el, props);
    return el;
}

const form = (opts) => c('form', opts);

const text = (tag, props) => {
    if (typeof props === 'string') {
        return c(tag, {innerHTML: props});
    } else {
        return c(tag, props);
    }
};

const h1 = (props) => text('h1', props);
const h2 = (props) => text('h2', props);
const h3 = (props) => text('h3', props);
const h4 = (props) => text('h4', props);
const h5 = (props) => text('h5', props);
const h6 = (props) => text('h6', props);

const p = (props) => text('p', props);

const div = (opts) => text('div', opts);

const button = (opts) => c('button', opts);

const node = (innerHTML) => {
    let _node = document.createElement('span');
};

const innerHTML = (innerHTML) => {
    let span = document.createElement('span');
    span.innerHTML = innerHTML;
    return span;
};

const _create = {
    button,
    div,
    form,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    node,
    innerHTML
};

function documentCommentExample() {
    let docu = new DOMParser().parseFromString('<xml></xml>',  'application/xml');
    let comment = docu.createComment('This is a not-so-secret comment in your document');
    docu.getElementsByTagName('xml')[0].appendChild(comment);
    alert(new XMLSerializer().serializeToString(docu));
}

function input({ list, ...props }) {
    const el = document.createElement('input');
    list && el.setAttribute('list', list);
    Object.assign(el, props);
    return el;
}

function _p_() {
    const element = document.createElement('p');
    if (id) element.id = id;
    if (style) Object.entries(style).forEach(([k, v]) => element.style[k] = v);
    if (attributes) Object.entries(attributes).forEach(([k, v]) => element.setAttribute(k, v));
    if (classList) element.classList.add(...classList);
    if (innerText) element.innerText = innerText;
    if (innerHTML) element.innerHTML = innerHTML;
    if (children) element.append(...children);
    if (onclick) element.onclick = onclick;
    return element;
}

const degreesToRadians = (degrees) => {
    return degrees * (Math.PI / 180);
};

const random = (min, max, float = false) => {
    const val = Math.random() * (max - min) + min;
    if (float) return val;
    return Math.floor(val);
};

function setUserSessionData(value) {
    if (sessionStorage.length) {
        console.log(value);
        sessionStorage.setItem('userSessionData', value);
    }
}

function getUserSessionData() {
    if (sessionStorage.length) {
        console.log('sessionStorage', sessionStorage);
        let data = JSON.parse(sessionStorage.getItem('userSessionData'));
        return {
            name: `${data['first name']} ${data['last name']}`.toLowerCase(),
            phone: data['phone number'].replace(/\D+/, ''),
            figure: {
                headColor: data['head color'],
                bodyColor: data['body color'],
            }
        };
    }
}

function cloneCanvas(oldCanvas) {
    let newCanvas = document.createElement('canvas');
    let context = newCanvas.getContext('2d');
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    context.drawImage(oldCanvas, 0, 0);
    return newCanvas;
}

// function robotIcon(params) {
//     // ctx.rotate(45 * Math.PI / 180);
//     // ctx.fillStyle = 'green';
//     // ctx.fillRect(140, 0, 40, 20);
//     // ctx.setTransform(1, 0, 0, 1, 0, 0);
//     //
//     // ctx.rotate(1);
//     // ctx.fillStyle = 'orange';
//     // ctx.fillRect(20, 0, 40, 20);
//     // ctx.setTransform(1, 0, 0, 1, 0, 0);
//
//     // ctx.clearRect(45, 45, 60, 60);
//     // ctx.fillRect(30, 20, 20, 10);
//     // ctx.strokeRect(50, 50, 50, 50);
// }

export {
    create,
    _create,
    degreesToRadians,
    random,
    setUserSessionData,
    getUserSessionData,
    cloneCanvas
};
