import {create} from '../../assets/utils/utils';

export const title = (innerHTML)  => {
    return create({
        tag: 'h2',
        innerHTML
    });
};

// function A() {
//     console.log(this);
//     return this;
// }
// let a = A.call(Ev);
// console.log(a);

class Article {
    constructor(title, date) {
        this.title = title;
        this.date = date;
    }

    static createTodays() {
        return new this('Today\'s digest', new Date());
    }
}

let worker = {
    slow(min, max) {
        alert(`Called with ${min},${max}`);
        return min + max;
    }
};

function cachingDecorator(func, hash) {
    let cache = new Map();
    return function() {
        let key = hash(arguments); // (*)
        if (cache.has(key)) {
            return cache.get(key);
        }

        let result = func.call(this, ...arguments); // (**)

        cache.set(key, result);
        return result;
    };
}

function hash(args) {
    return [].join.call(args);
}

// worker.slow = cachingDecorator(worker.slow, hash);

function sayHi() {
    alert(this.name);
}

// let user = { name: 'John' };
// let admin = { name: 'Admin' };

// use call to pass different objects as "this"
// sayHi.call( user ); // John
// sayHi.call( admin ); // Admin
