import {create} from '../../assets/utils/utils';
import {buttonOpenDetails, buttonOpenRsvp} from '../buttons';

export default function topNavigation() {
    const disabledColor = '#c1c1c1';
    const _buttonOpenRsvp = create({
        tag: 'button',
        innerHTML: 'rsvp (closed)',
        style: {
            color: disabledColor,
            borderColor: disabledColor,
            cursor: 'auto',
        }
    });

    _buttonOpenRsvp.disabled = true;

    return create({
        tag: 'div',
        classList: ['top-nav'],
        children: [
            _buttonOpenRsvp,
            buttonOpenDetails()
        ]
    });
}
