const registry = new Set();

const id = {
    rsvp_form: 'rsvp_form',
    event_details: 'event_details'
};

function bind() {
    console.log(`binding id: ${this}`);

    if (registry.has(this)) {
        console.log(`cannot bind id: ${this}`);
        return;
    }

    registry.add(this);
    return this;
}

function unbind(id) {
    registry.delete(id);
    return id;
}

(() => {
    Object.keys(id).forEach(id => bind.call(id));
})();

export default id;
