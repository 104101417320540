import {create} from '../../assets/utils/utils';

// export class Overlay {
//     constructor(properties) {
//         this.element = create({tag: 'div', id: 'overlay'});
//         this.button = create({tag: 'button', innerText: 'enter'});
//         this.properties = properties;
//     }
//
//     test() {
//     }
// }

// FIXME: wtf
export default function overlay({topText, buttonText, bottomText, buttonClick}) {
    const obj = create({tag: 'div', id: 'overlay'});
    const btn = create({tag: 'button', innerText: buttonText});

    btn.onclick = () => {
        document.querySelector(`#${obj.id}`).remove();
        if (buttonClick) buttonClick();
    };

    obj.append(topText, btn, bottomText);

    return obj;
}
