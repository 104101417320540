import * as THREE from 'three';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js';
import fontJson from '../../assets/fonts/Inconsolata_Regular.json';
import {random, degreesToRadians} from '../../assets/utils/utils';

export const fontLoader = new FontLoader();

const MIRROR = [-1, 1];

const matName = new THREE.MeshBasicMaterial({
    color: '#000000',
    transparent: true,
    opacity: 0.9,
    side: THREE.DoubleSide
});

export const SIZES = {
    width: window.innerWidth,
    height: window.innerHeight,
    figureSpacing: 0.5,
    figureBody: [5, 6, 5],
    figureHead: [8, 6, 7],
    figureEyes: [0.75, 60, 44],
    figureLegs: [1.25, 2.5, 1.25],
    figureArms: [1.25, 3, 1.25],
};

class Robot {
    constructor(params, scene, camera) {
        this.group = new THREE.Group();

        this.params = params;
        this.camera = camera;

        scene.add(this.group);

        this.group.position.x = this.params.x;
        this.group.position.y = this.params.y;
        this.group.position.z = this.params.z;

        this.group.rotateX(this.params.rotateX);
        this.group.rotateY(this.params.rotateY);
        this.group.rotateZ(this.params.rotateZ);

        this.headMaterial = new THREE.MeshLambertMaterial({color: params.guestInfo.figure.headColor});
        this.bodyMaterial = new THREE.MeshLambertMaterial({color: params.guestInfo.figure.bodyColor});

        this.arms = [];
    }

    createBody() {
        this.body = new THREE.Group();
        const geometry = new THREE.BoxGeometry(...SIZES.figureBody);
        const bodyMain = new THREE.Mesh(geometry, this.bodyMaterial);
        this.body.add(bodyMain);
        this.group.add(this.body);
        this.createNameTag();
        this.createLegs();
    }

    createNameTag() {
        const guest = this.params.guestInfo.name;
        const nameTag = new THREE.Group();

        fontLoader.load(fontJson, (font) => {
            const shapes = font.generateShapes(guest, 2);
            const geometry = new THREE.ShapeGeometry(shapes);
            geometry.computeBoundingBox();
            const text = new THREE.Mesh(geometry, matName);

            nameTag.position.x = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
            nameTag.position.y = SIZES.figureHead[1] + 8;
            nameTag.add(text);
        });

        this.nameTag = nameTag;
        this.group.add(this.nameTag);
    }

    createHead() {
        this.head = new THREE.Group();
        const geometry = new THREE.BoxGeometry(...SIZES.figureHead);
        const headMain = new THREE.Mesh(geometry, this.headMaterial);
        this.head.add(headMain);
        this.group.add(this.head);
        this.head.position.y = SIZES.figureHead[1] + SIZES.figureSpacing;
        this.createEyes();
    }

    createArms() {
        for (let m of MIRROR) {
            const armGroup = new THREE.Group();
            const geometry = new THREE.BoxGeometry(...SIZES.figureArms);
            const arm = new THREE.Mesh(geometry, this.headMaterial);

            armGroup.add(arm);
            this.body.add(armGroup);
            arm.position.y = SIZES.figureArms[1] * -0.5;
            armGroup.position.x = SIZES.figureArms[1] * m;
            armGroup.position.y = SIZES.figureArms[1] * 0.5 + 1;
            armGroup.rotation.z = degreesToRadians(30 * m);
            this.arms.push(armGroup);
        }
    }

    createEyes() {
        const eyes = new THREE.Group();
        const geometry = new THREE.SphereGeometry(...SIZES.figureEyes);
        const material = new THREE.MeshLambertMaterial({color: 0x44445c});
        for (let m of MIRROR) {
            const eye = new THREE.Mesh(geometry, material);
            eyes.add(eye);
            eye.position.x = SIZES.figureHead[1] / 4 * m;
        }
        this.head.add(eyes);
        eyes.position.z = SIZES.figureHead[2] / 2;
    }

    createLegs() {
        const legs = new THREE.Group();
        const geometry = new THREE.BoxGeometry(...SIZES.figureLegs);
        for (let m of MIRROR) {
            const leg = new THREE.Mesh(geometry, this.headMaterial);
            legs.add(leg);
            leg.position.x = m * SIZES.figureLegs[1] / 2;
        }
        this.group.add(legs);
        legs.position.y = -SIZES.figureBody[1] + SIZES.figureLegs[0] + SIZES.figureSpacing;
        this.body.add(legs);
    }

    // __bounce
    bounce() {
        // this.group.rotation.y = this.params.ry;
        // this.nameTag.position.x = Math.sin(this.params.ry) * 2;
        // this.nameTag.rotation.y = this.params.ry;

        this.nameTag.lookAt( this.camera.position );

        this.group.position.y = this.params.y;

        this.arms.forEach((arm, index) => {
            const m = index % 2 === 0 ? 1 : -1;
            arm.rotation.z = m * -Math.PI / 4;
        });
    }

    init() {
        this.createBody();
        this.createHead();
        this.createArms();
    }
}

function buildRobots(boxDimensions, guestListInfo, gsap, scene, camera) {
    let minX = boxDimensions.min.x;
    let maxX = boxDimensions.max.x;
    let minZ = boxDimensions.min.z;
    let maxZ = boxDimensions.max.z;

    let y = boxDimensions.max.y + SIZES.figureBody[1];
    let radianScope = Math.PI * 2;

    for (let guestInfo of guestListInfo) {
        buildRobot({
            guestInfo,
            x: random(minX, maxX),
            y,
            z: random(minZ, maxZ),
            rotateX: 0,
            rotateY: random(0, radianScope, true),
            rotateZ: 0,
            ry: 0,
            bounce: 0,
            armBounce: random(0, 20, true)
        }, gsap, scene, camera);
    }
}

function buildRobot(params, gsap, scene, camera) {
    const bot = new Robot(params, scene, camera);
    bot.init();

    gsap.set(bot.params, {y: params.y});

    gsap.to(bot.params, {
        ry: Math.PI * 2,
        repeat: -1,
        duration: 20
    });

    gsap.to(bot.params, {
        y: params.y + params.bounce,
        repeat: -1,
        yoyo: true,
        duration: params.armBounce
    });

    gsap.ticker.add(() => {
        bot.bounce();
    });
}

export {buildRobots};
