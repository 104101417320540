import {getUserSessionData} from '../assets/utils/utils';
import {GUEST_INFO_SHEET} from '../modules/common/constants';

async function fetchGuestInfo() {
    return fetch(GUEST_INFO_SHEET)
        .then(res => res.text())
        .then(res => {
            let invites = res.split('\r\n');
            invites.shift();

            let sessionData = getUserSessionData();

            return invites.map(line => {
                let [paid, first, last, phone, headColor, bodyColor, email] = line.split(',');
                let name = `${first} ${last}`.toLowerCase();

                let figure;

                if (sessionData && sessionData.phone === phone && sessionData.name === name) {
                    figure = sessionData.figure;
                } else {
                    figure = {headColor, bodyColor};
                }

                return {
                    name,
                    paid: (paid === 'TRUE'),
                    phone,
                    figure
                };
            });
        });
}

export const guestList = (async () => await fetchGuestInfo())();
