import {create} from '../../assets/utils/utils';

const buttonOpenDetails = () => create({
    tag: 'button',
    innerHTML: 'event details',
    style: {
        float: 'right'
    },
    dataset: {
        action: 'OPEN_DETAILS'
    }
});

const buttonOpenRsvp = () => create({
    tag: 'button',
    innerHTML: 'rsvp form',
    dataset: {
        action: 'OPEN_RSVP'
    },
});

const buttonCloseRsvp = () => create({
    tag: 'button',
    classList: ['close'],
    innerHTML: 'x',
    dataset: {
        action: 'CLOSE_RSVP'
    }
});

const buttonCloseDetails = () => create({
    tag: 'button',
    classList: ['close'],
    innerHTML: 'x',
    dataset: {
        action: 'CLOSE_DETAILS'
    }
});

export {
    buttonOpenDetails,
    buttonCloseDetails,
    buttonOpenRsvp,
    buttonCloseRsvp
};
