import './styles.css';
import overlay from './modules/common/overlay';
import topNavigation from './modules/come-thru/top-navigation';
import dock from './modules/come-thru/dock';
import eventDetails from './modules/come-thru/event-details';
import {create} from './assets/utils/utils';
import {fromEvent} from 'rxjs';
import {transitionIn, transitionOut} from './modules/common/transitions';
import {buttonOpenDetails} from './modules/buttons';
import id from './modules/events/id';

const root = document.querySelector('#root');

function landingPage() {
    const obj = create({
        tag: 'div',
        id: 'overlay'
    });

    const points = (innerHTML) => {
        return create({
            tag: 'p',
            style: {
                paddingBottom: '20px',
            },
            innerHTML
        });
    };

    const message = create({
        tag: 'div',
        style: {
            padding: '24px',
            textAlign: 'center',
        },
        children: [
            'rkn collective values & prioritizes the safety & well-being of everyone in our community to the highest importance',
            'if you have anything you would like to bring to our attention, please don’t hesitate to reach out to us',
            'as a group, we work to uphold our morals & values to the highest extent & will always strive to create a safe & inviting space for all',
            'please reach out to <a href="sms:+1-469-734-6739">elizabeth song</a> if you have any questions or concerns'
        ]
            .map(points)
            .concat(create({
                tag: 'p',
                innerHTML: ' - rkn collective - ',
                style: {
                    padding: '20px'
                }
            }))
    });

    let _buttonOpenDetails = buttonOpenDetails();

    _buttonOpenDetails.style.width = '132px';
    _buttonOpenDetails.style.borderTopLeftRadius = 0;
    _buttonOpenDetails.style.borderBottomLeftRadius = 0;
    _buttonOpenDetails.style.color = '#dfa9a9';
    _buttonOpenDetails.style.borderColor = '#dfa9a9';

    let buttonRemoveOverlay = create({
        tag: 'button',
        innerText: 'enter dock',
        style: {
            width: '132px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            color: '#dfa9a9',
            borderColor: '#dfa9a9',
        }
    });

    let buttonGroup = create({
        tag: 'div',
        style: {
            paddingBottom: '40px'
        },
        children: [
            _buttonOpenDetails,
            buttonRemoveOverlay
        ]
    });

    fromEvent(buttonRemoveOverlay, 'click')
        .subscribe(e => {
            document.querySelector('#overlay').remove();
        });

    obj.append(
        create({
            tag: 'h3',
            innerHTML: ' - to our friends & family - ',
            style: {
                padding: 0
            }
        }),
        message,
        buttonGroup,
    );

    return obj;
}

function initSubscribers() {
    fromEvent(document.querySelectorAll('button'), 'click')
        .subscribe(e => {
            console.log(e.target.dataset.action);

            switch (e.target.dataset.action) {
                // case 'OPEN_RSVP':
                //     transitionIn(`#${id.rsvp_form}`);
                //     break;
                // case 'CLOSE_RSVP':
                //     transitionOut(`#${id.rsvp_form}`);
                //     break;
                case 'OPEN_DETAILS':
                    transitionIn(`#${id.event_details}`);
                    break;
                case 'CLOSE_DETAILS':
                    transitionOut(`#${id.event_details}`);
                    break;
                default:
                    return;
            }
        });
}

(function init() {

    root.append(
        landingPage(),
        dock(),
        topNavigation()
    );

    eventDetails()
        .then(details => root.append(details))
        .then(() => {
            initSubscribers();
        });
})();
