import {cloneCanvas, create} from '../../assets/utils/utils';
import {guestList} from '../../api';
import {title} from '../micro/micro';
import {buttonCloseDetails} from '../buttons';
import id from '../events/id';

function buildEmptyRobot() {
    const canvas = document.createElement('canvas');

    canvas.width = 50;
    canvas.height = 60;

    const ctx = canvas.getContext('2d');
    ctx.setLineDash([6, 2]);

    ctx.strokeStyle = 'rgb(200,200,200)';
    ctx.strokeRect(0, 0, 50, 28);

    ctx.strokeRect(10, 30, 30, 30);

    ctx.beginPath();
    ctx.arc(15, 12, 4, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(35, 12, 4, 0, 2 * Math.PI);
    ctx.stroke();

    return canvas;
}

export function robotIcon({headColor, bodyColor}) {
    const canvas = document.createElement('canvas');

    canvas.width = 50;
    canvas.height = 60;

    const ctx = canvas.getContext('2d');

    ctx.fillStyle = headColor;
    ctx.fillRect(0, 0, 50, 28);

    ctx.fillStyle = bodyColor;
    ctx.fillRect(10, 30, 30, 30);

    ctx.beginPath();
    ctx.arc(15, 12, 4, 0, 2 * Math.PI);
    ctx.arc(35, 12, 4, 0, 2 * Math.PI);
    ctx.fillStyle = 'black';
    ctx.fill();

    return canvas;
}

export default async function eventDetails() {
    const eventDetailsSection = create({
        tag: 'div',
        id: id.event_details,
        style: {
            padding: '20px 0',
            zIndex: 120
        },
        classList: ['menu-overlay']
    });

    const invites = await guestList;

    const emptyRobotIcon = buildEmptyRobot();

    const invitesHtml = invites.map(({name, paid, figure}) => {
        return create({
            tag: 'div',
            classList: ['grid-item', (paid ? 'paid' : 'unpaid')],
            children: [
                create({
                    tag: 'p',
                    innerHTML: name
                }),
                paid
                    ?
                    robotIcon({
                        headColor: figure.headColor,
                        bodyColor: figure.bodyColor,
                    })
                    :
                    cloneCanvas(emptyRobotIcon)
            ]
        });
    });

    eventDetailsSection.append(
        buttonCloseDetails(),
        title('hello'),
        create({
            tag: 'div',
            children: [
                'it\'s been a minute',
            ].map(text => create({
                tag: 'p',
                style: {
                    lineHeight: '20px'
                },
                innerHTML: text
            }))
        }),

        title('recap'),
        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                create({
                    tag: 'p',
                    innerHTML: '░ we acknowledge the gradual return of festivals & live music events and realize that it\'s time to get back to the experiences we love',
                })
            ]
        }),

        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                create({
                    tag: 'p',
                    innerHTML: '░ we booked a luxury boat that leaves from <a href="https://www.facebook.com/SilverLakeMarina/">silver lake marina</a> on 07-16-2022 and would be happy if you could make it',
                })
            ]
        }),

        title('event details'),
        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                'theme: ⏅ <i>boat</i>chella',
                'date: saturday, july 16th, 2022',
                'time: 6:30pm - 10:00pm',
                'location: <a target="_blank" href="https://goo.gl/maps/D7HtNZErCCSEQKS69">sam\'s dock @ lake grapevine</a>',
                'price: $40.00',
                'venmo: <a target="_blank" href="https://account.venmo.com/u/rkncollective">@rkncollective</a>',
            ].map(text => create({
                tag: 'p',
                innerHTML: text
            }))
        }),

        title('performances'),
        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                'yes child, there will be music.',
                'we are especially excited to have some very special guests join us in contributing to good vibes through music, ' +
                'be sure to give a warm welcome to <a target="_blank" href="https://open.spotify.com/artist/0sAoUlJaQX04Buai1yBFt2?si=95b7005ad9ac4b9e">devyn moon</a> ' +
                'and <a target="_blank" href="https://open.spotify.com/artist/5IFrkkA655lA4x9B56aaIa?si=ecd4c642f7b04e58">svny</a> when you see them on the boat'

            ].map(text => create({
                tag: 'p',
                innerHTML: text
            }))
        }),

        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                '- performances by -',
                '░ devyn moon',
                '░ svny',
                '░ rkn'
            ].map(text => create({
                tag: 'p',
                innerHTML: text
            }))
        }),

        title('cruise day info'),
        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                '░ this event is byob and 21+',
                '░ boat has max capacity of 100 guests',
                '░ no swimming allowed',
                '░ plan to arrive early, boarding is at 6:30',
                '░ boat will depart exactly at 7:00 and will not wait for you',
            ].map(text => create({
                tag: 'p',
                innerHTML: text
            }))
        }),

        title('guest list'),

        create({
            tag: 'div',
            style: {
                lineHeight: '20px'
            },
            children: [
                '░ 100/100 spots filled',
            ].map(text => create({
                tag: 'p',
                innerHTML: text
            }))
        }),

        create({
            tag: 'div',
            classList: ['flex-container'],
            children: invitesHtml
        }),
        create({
            tag: 'div',
            style: {
                textAlign: 'center'
            },
            children: [
                create({
                    tag: 'h3',
                    innerHTML: 'ⓡⓚⓝ'
                })
            ]
        }),
    );

    return eventDetailsSection;
}
