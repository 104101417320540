import * as THREE from 'three';
import gsap from 'gsap';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {Water} from 'three/examples/jsm/objects/Water.js';
import {Sky} from 'three/examples/jsm/objects/Sky.js';
import {buildRobots, fontLoader} from './robot.js';
import fontJson from '../../assets/fonts/Inconsolata_Regular.json';
import waterNormals from '../../assets/images/waternormals.jpg';
import cubeFace from '../../assets/images/try-too.JPG';
import {create} from '../../assets/utils/utils';
import {guestList} from '../../api';

const FONT_COLOR = '#ffffff';
const GEO_PLANE = 10000;
const FONT_SIZE = 12;

const container = create({
    tag: 'div',
    id: 'container'
});

let scene, camera, renderer, uniforms, controls,
    water, sun, mesh, stats, cubeMesh, canvas, boxGeometry;

let cubeSide = Math.max(window.innerWidth / 6, 200);

let messages = [
    {
        content: `
rkn lux cruise

   07-16-22
`,
        rotateY: 0,
    },
    {
        content: `
boarding:  6:30pm

departure: 7:00pm
`,
        rotateY: Math.PI / 2,
    },
    {
        content: `
performances by:
- devyn moon
- svny
- rkn
`,
        rotateY: Math.PI * 3 / 2,
    },
];

const matDark = new THREE.LineBasicMaterial({
    color: FONT_COLOR,
    side: THREE.DoubleSide
});

const matLite = new THREE.MeshBasicMaterial({
    color: FONT_COLOR,
    transparent: true,
    opacity: 0.9,
    side: THREE.DoubleSide
});

function loadMessages({content, rotateY}) {
    fontLoader.load(fontJson, function (font) {

        const shapes = font.generateShapes(content, FONT_SIZE);
        const geometry = new THREE.ShapeGeometry(shapes);
        geometry.computeBoundingBox();

        const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x); // justify content center
        const yMid = -0.5 * (geometry.boundingBox.max.y - geometry.boundingBox.min.y); // align items center

        const translation = [xMid, cubeSide - yMid + 20, cubeSide / 2 + 20];
        geometry.translate(...translation).rotateY(rotateY);

        const text = new THREE.Mesh(geometry, matLite);
        text.position.z = -.5;
        scene.add(text);

        const holeShapes = [];

        for (let shape of shapes) {
            if (shape.holes && shape.holes.length) {
                for (let hole of shape.holes) {
                    holeShapes.push(hole);
                }
            }
        }

        shapes.push.apply(shapes, holeShapes);
        const lineText = new THREE.Object3D();

        for (let shape of shapes) {
            const points = shape.getPoints();
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            geometry.translate(...translation).rotateY(rotateY);

            const lineMesh = new THREE.Line(geometry, matDark);
            lineText.add(lineMesh);
        }

        scene.add(lineText);

        render();
    });
}

function makeCube() {
    let textureLoader = new THREE.TextureLoader();

    const imageTexture = textureLoader.load(cubeFace);

    let cubeGeometry = new THREE.BoxGeometry(cubeSide, cubeSide, cubeSide);
    let cubeMaterial = new THREE.MeshPhongMaterial({map: imageTexture});

    cubeMesh = new THREE.Mesh(cubeGeometry, Array(6).fill(cubeMaterial));

    scene.add(cubeMesh);
    cubeMesh.position.set(0, cubeSide, 0);

    addAmbientLighting();
}

function addAmbientLighting() {
    scene.add(new THREE.AmbientLight(0xffffff, 0.65));
}

// function addDirectionalLighting() {
//     let directions = [
//         {x: 0, y: 0, z: 0}, // bottom
//         {x: 0, y: cubeSide * 2, z: 0}, // top
//     ];
//
//     for (let {x, y, z} of directions) {
//         const pointLight = new THREE.PointLight(0xffffff, 0.5);
//         pointLight.position.x = x;
//         pointLight.position.y = y;
//         pointLight.position.z = z;
//         scene.add(pointLight);
//     }
// }

// export function init() {
//     const audioElement = new Audio(tryToo);
//
//     if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
//         document.addEventListener('visibilitychange', event => {
//             if (document.visibilityState === 'visible') {
//                 audioElement.play();
//             } else {
//                 audioElement.pause();
//             }
//         });
//     }
//
//     audioElement.play();
//
//     // TOGGLE ROTATION
//     // window.addEventListener('mousedown', stopRotate);
//     // window.addEventListener('touchstart', stopRotate);
// }

// function stopRotate(e) {
//     if (controls.autoRotate) {
//         controls.autoRotate = false;
//     }
// }

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
    requestAnimationFrame(animate);
    render();
}

function render() {
    controls.update();
    water.material.uniforms['time'].value += 1.0 / 60.0;
    renderer.render(scene, camera);
}

(function preload() {
    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;

    container.appendChild(renderer.domElement);

    scene = new THREE.Scene();

    camera = new THREE.PerspectiveCamera(100, window.innerWidth / window.innerHeight, 1, GEO_PLANE * 2);
    camera.position.set(0, 25, 1000);

    boxGeometry = new THREE.BoxGeometry(cubeSide * 2, cubeSide / 4, cubeSide * 2);
    const standardMaterial = new THREE.MeshStandardMaterial({roughness: 0, color: 'rgb(255,255,255)'});

    mesh = new THREE.Mesh(boxGeometry, standardMaterial);
    scene.add(mesh);

    boxGeometry.computeBoundingBox();

    sun = new THREE.Vector3();

    const waterGeometry = new THREE.PlaneGeometry(GEO_PLANE, GEO_PLANE);

    water = new Water(waterGeometry, {
        textureWidth: 512,
        textureHeight: 512,
        waterNormals: new THREE.TextureLoader().load(waterNormals, function (texture) {
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        }),
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x001e0f,
        distortionScale: 3.7,
        fog: scene.fog !== undefined
    });

    water.rotation.x = -Math.PI / 2;

    const sky = new Sky();
    sky.scale.setScalar(GEO_PLANE);

    scene.add(water);
    scene.add(sky);

    const skyUniforms = sky.material.uniforms;

    skyUniforms['turbidity'].value = 10;
    skyUniforms['rayleigh'].value = 2;
    skyUniforms['mieCoefficient'].value = 0.005;
    skyUniforms['mieDirectionalG'].value = 0.8;

    const parameters = {elevation: 3, azimuth: 0};

    const pmremGenerator = new THREE.PMREMGenerator(renderer);

    function updateSun() {
        const phi = THREE.MathUtils.degToRad(90 - parameters.elevation);
        const theta = THREE.MathUtils.degToRad(parameters.azimuth);
        sun.setFromSphericalCoords(1, phi, theta);
        sky.material.uniforms['sunPosition'].value.copy(sun);
        water.material.uniforms['sunDirection'].value.copy(sun).normalize();
        scene.environment = pmremGenerator.fromScene(sky).texture;
    }

    updateSun();

    controls = new OrbitControls(camera, renderer.domElement);
    controls.maxPolarAngle = Math.PI * 0.49;
    controls.target.set(0, 30, 0);
    controls.minDistance = 30;
    controls.maxDistance = 600;
    controls.autoRotate = true;
    controls.autoRotateSpeed = -1.0;
    controls.zoomSpeed = 0.5;
    controls.update();

    window.addEventListener('resize', onWindowResize);

    for (let message of messages) {
        loadMessages(message);
    }

    makeCube();
    animate();
})();

export default function dock() {
    Promise.resolve(guestList)
        .then(guestListInfo => {
            // let paidGuests = guestListInfo.filter(guest => guest.paid);
            // buildRobots(boxGeometry.boundingBox, paidGuests, gsap, scene, camera);
            buildRobots(boxGeometry.boundingBox, guestListInfo, gsap, scene, camera);

            let m = {
                content: `
100/100 spots filled

  guest list below
`,
                rotateY: Math.PI,
            };

            loadMessages(m);
        });

    return container;
}
